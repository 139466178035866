<script setup>
import { animate } from 'motion'

const { animateLerp } = useReactiveLerp()

const props = defineProps({
    animation: {
        type: [Boolean, Array, Object],
        required: false,
        default: false,
    },
    optionsDefaults: {
        type: Object,
        required: false,
        default: () => ({
            duration: 1, easing: 'linear', fill: 'both', composite: 'accumulate', allowWebkitAcceleration: true,
        }),
    },
    options: {
        type: Object,
        required: false,
        default: () => ({}),
    },
    enter: {
        type: [Boolean, Array, Object],
        required: false,
        default: () => ({ transform: ['translateY(1.5em) translateZ(0)', 'translateY(0em) translateZ(0)'] }),
    },
    enterOptionsDefaults: {
        type: Object,
        required: false,
        default: () => ({
            duration: 1, easing: 'cubic-bezier(0,.48,.38,1)', fill: 'both', composite: 'replace', allowWebkitAcceleration: true,
        }),
    },
    enterOptions: {
        type: Object,
        required: false,
        default: () => ({}),
    },
    leave: {
        type: [Boolean, Array, Object],
        required: false,
        default: () => ({ }),
    },
    leaveOptionsDefaults: {
        type: Object,
        required: false,
        default: () => ({
            duration: 1, easing: 'cubic-bezier(0,.48,.38,1)', fill: 'both', composite: 'replace', allowWebkitAcceleration: true,
        }),
    },
    leaveOptions: {
        type: Object,
        required: false,
        default: () => ({}),
    },
    invisibleClass: { type: String, required: false, default: 'parallax-invisible' },
    visibleClass: { type: String, required: false, default: 'parallax-visible' },
    appear: { type: Boolean, required: false, default: true },
    offset: { type: Number, required: false, default: 0 },
    once: { type: Boolean, required: false, default: false },
    classOnce: { type: Boolean, required: false, default: false },
    stagger: { type: [String, Number], required: false, default: 'auto' },
    setHeight: { type: Boolean, required: false, default: false },
    lerp: { type: Number, required: false, default: 0 },
})

const attrs = useAttrs()
const emit = defineEmits(['enter', 'leave', 'progress'])

const cssClasses = ref('')
if (props.appear && attrs.class) {
    cssClasses.value = [...attrs.class.split(' '), ...props.invisibleClass.split(' ')].join(' ')
}
const root = ref(null)
const parallaxAnimations = ref([])
const done = ref(false)
const classDone = ref(false)
const split = ref([])
const subsplits = ref([])
const splitVisible = ref(false)
const hasSplitted = computed(() => split?.value?.chars?.length > 0)

const updateparallaxAnimations = (currentProgress) => {
    emit('progress', currentProgress)
    for (let i = 0, il = parallaxAnimations.value.length; i < il; ++i) {
        const animation = parallaxAnimations.value[i]
        const activeTime = (props.options.duration * currentProgress) - props.offset
        animation.currentTime = activeTime * 2.25
    }

    if (props.once && parallaxAnimations.value[0].currentTime >= activeTime) {
        done.value = true
    }
}

const render = (currentProgress) => {
    if (!isServer()) {
        if (parallaxAnimations.value && parallaxAnimations.value?.length > 0 && !done.value) {
            if (props.lerp) {
                animateLerp(currentProgress, props.lerp, updateparallaxAnimations)
            }
            else {
                updateparallaxAnimations(currentProgress)
            }
        }
    }
}

const visibilityEnter = () => {
    if (!isServer()) {
        emit('enter', root.value.$el)
        splitVisible.value = true

        if (props.enter && split?.value?.chars?.length) {
            const splitLength = split.value.chars.length
            for (let i = 0, il = splitLength; i < il; ++i) {
                const char = split.value.chars[i]
                const { normalizedStyles } = normalizeStyles(char, props.enter)
                const mergedOptions = { ...props.enterOptionsDefaults, ...props.enterOptions }
                if (props.stagger) {
                    let calculatedDelay
                    if (props.stagger === 'auto') {
                        calculatedDelay = ((mergedOptions.duration / splitLength) * i) / 2
                    }
                    else {
                        calculatedDelay = props.stagger * i
                    }
                    mergedOptions.delay = calculatedDelay
                }
                animate(char, normalizedStyles, mergedOptions)
            }
        }

        if (!classDone.value && split?.value?.chars?.length) {
            for (let i = 0, il = split.value.chars.length; i < il; ++i) {
                const char = split.value.chars[i]
                if (props.invisibleClass) {
                    char.classList.remove(...props.invisibleClass.split(' '))
                }
                if (props.visibleClass) {
                    char.classList.add(...props.visibleClass.split(' '))
                }
            }

            if (props.classOnce) {
                classDone.value = true
            }
        }
    }
}

const visibilityLeave = () => {
    if (!isServer()) {
        emit('leave', root.value.$el)
        splitVisible.value = false

        if (props.leave && split?.value?.chars?.length) {
            const splitLength = split.value.chars.length
            for (let i = 0, il = split.value.chars.length; i < il; ++i) {
                const char = split.value.chars[i]
                const { normalizedStyles } = normalizeStyles(char, props.leave)
                const mergedOptions = { ...props.leaveOptionsDefaults, ...props.leaveOptions }
                if (props.stagger) {
                    let calculatedDelay
                    if (props.stagger === 'auto') {
                        calculatedDelay = ((mergedOptions.duration / splitLength) * i) / 2
                    }
                    else {
                        calculatedDelay = props.stagger * i
                    }
                    mergedOptions.delay = calculatedDelay
                }
                animate(char, normalizedStyles, mergedOptions)
            }
        }

        if (!classDone.value && split?.value?.chars?.length) {
            for (let i = 0, il = split.value.chars.length; i < il; ++i) {
                const char = split.value.chars[i]
                if (props.invisibleClass) {
                    char.classList.add(...props.invisibleClass.split(' '))
                }
                if (props.visibleClass) {
                    char.classList.remove(...props.visibleClass.split(' '))
                }
            }
        }
    }
}

// magical function
const nestedLinesSplit = (target, vars) => {
    const targets = gsap.utils.toArray(target)
    let splity = null
    targets.forEach((t) => {
        gsap.utils.toArray(t.children).forEach((child) => {
            splity = new SplitText(child, { type: 'lines' })
            splity.lines.forEach((line) => {
                const clone = child.cloneNode(false)
                clone.innerHTML = line.innerHTML
                t.insertBefore(clone, child)
            })
            t.removeChild(child)
        })
    })

    splity = new SplitText(targets, vars)

    if (targets.length > 1) {
        for (let i = 0; i < targets.length; i++) {
            const subsplit = nestedLinesSplit(targets[i], vars)
            subsplits.value.push(subsplit)
            splity.lines = splity.lines.concat(subsplit.lines)
        }
    }
    return splity
}

const makeSplit = (el) => {
    const parentHeight = window.getComputedStyle(el).height

    split.value = nestedLinesSplit(el, {
        type: 'words,chars',
        // position: 'absolute',
        wordsClass: `SplitText__child-word overflow-clip h-[1.6cap] will-change-transform `,
        charsClass: `SplitText__child-char overflow-clip h-[1.6cap] will-change-transform  ${cssClasses.value}`,
        // linesClass: 'SplitText__child-line whitespace-nowrap overflow-clip',
    })

    if (split?.value?.chars?.length) {
        for (let i = 0, il = split.value.chars.length; i < il; ++i) {
            const char = split.value.chars[i]

            if (props.animation) {
                const { normalizedStyles } = normalizeStyles(char, props.animation)

                const splitLength = split.value.chars.length
                const mergedOptions = { ...props.optionsDefaults, ...props.options }
                if (props.stagger) {
                    let calculatedDelay
                    if (props.stagger === 'auto') {
                        calculatedDelay = ((mergedOptions.duration / splitLength) * i) / 2
                    }
                    else {
                        calculatedDelay = props.stagger * i
                    }
                    mergedOptions.delay = calculatedDelay
                }
                const anim = animate(char, normalizedStyles, mergedOptions)
                anim.pause()
                parallaxAnimations.value.push(anim)
            }

            if (props.setHeight) {
                char.style.height = parentHeight
            }

            if (props.visibleClass && props.stagger > 0) {
                // Combine with a custom class of .delay-variable in Tailwind -> { transitionDelay: 'var(--tw-transition-delay)' }
                char.style.setProperty('--tw-transition-delay', `${props.stagger * i}ms`)
            }
        }
    }

    if (props.setHeight) {
        root.value.$el.children[0].style.height = parentHeight
    }
}

const destroySplit = () => {
    parallaxAnimations.value = []
    if (subsplits.value.length) {
        for (let i = 0, il = subsplits.value.length; i < il; ++i) {
            const sub = subsplits.value[i]
            sub.revert()
        }
    }

    if (split.value) {
        split.value.revert()
    }
}

const rebuildSplit = () => {
    destroySplit()
    makeSplit(root.value.$el.children[0])
}
const debouncedRebuild = debounce(rebuildSplit, 250)

onMounted(() => {
    // Avoid splitting the code when the font is not loaded
    // prevent sa bug in chrome when fonts load too fast
    document.fonts.ready.then(() => {
        if (root?.value?.$el?.children?.[0]) {
            makeSplit(root.value.$el.children[0])
            if (splitVisible.value) {
                visibilityEnter()
            }
        }
    })

    window.addEventListener('orientationchange', debouncedRebuild)
    window.addEventListener('resize', debouncedRebuild)
})

onUnmounted(() => {
    if (!isServer()) {
        destroySplit()

        window.removeEventListener('orientationchange', debouncedRebuild)
        window.removeEventListener('resize', debouncedRebuild)
    }
})
</script>

<template>
    <VisibleProgress
        ref="root"
        v-slot="{ visible, progress }"
        :class="{ '!opacity-0': !splitVisible || !hasSplitted }"
        v-on:enter="visibilityEnter"
        v-on:leave="visibilityLeave"
        v-on:progress="render"
    >
        <slot
            :visible="visible"
            :progress="progress"
        />
    </VisibleProgress>
</template>

<style scoped>
:deep(strong, em, i) {
    display: inline;
}
</style>
